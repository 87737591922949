<template>
  <div id="speech">
    <div class="banner">
      <h1>演讲专题</h1>
      <div class="input">
        <input
          type="text"
          id="search_input"
          placeholder="输入领域或者关键词，查看相关主题"
          v-model="search"
          @keyup.enter="toSearch('enter')"
        />
        <button type="button" id="search_button" @click="toSearch('btn')">搜索一下</button>
      </div>
      <div class="keyword">
        <a href="javascript:;" class="tag" @click="toSearch('tag', $event)" v-for="(key, index) in keywords" :key="'key'+index">{{ key }}</a>
      </div>
    </div>
    <div class="project" v-if="project.length > 0">
      <Loading :loading="isLoading"></Loading>
      <div class="projectz">
        <slot v-for="(item, index) in project">
          <section v-if="!(item.venueName && item.venueName.includes('开幕式'))" :key="'section_' + index">
            <a class="pro_top_01 all_a" @click="toNext('subject', item.id)">
              <h1>{{ item.venueName }}</h1>
              <!-- <p></p> -->
            </a>
            <ul class="pro_bot">
              <li class="course-div" v-for="(it, i) in item.schedulingCourse" :data-id="it.courseid" :key="'li_course_' + i">
                <div class="all" @click="it.courseid ? toNext('course', it.courseid) : ''">
                  <div class="top row">
                    <div class="le">
                      <div class="img" :class="it.thumbs?'':'blank'">
                        <img v-if="it.thumbs" :src="it.thumbs" alt="">
                        <!-- <img :src="global.getSrc(it.thumbs)" alt=""> -->
                      </div>
                    </div>
                    <div class="ri">
                      <a>
                        <h1 class="course-title">{{ it.courseTitle }}</h1>
                      </a>
                      <h2>
                        <span>{{ it.name }}</span
                        ><br />{{ it.company }} <br />{{ it.position }}
                      </h2>
                    </div>
                  </div>
                  <div class="bot"></div>
                </div>
              </li>
            </ul>
          </section>
        </slot>
        <p class="search_blank" v-if="noneShowList">未搜索到 {{searchwordshow}} 相关专题或课程</p>
      </div>
    </div>
    <div class="isblank" v-else>敬请期待</div>
  </div>
</template>

<script>
import $ from "jquery";
import { sid, scheduledifference, getMeetingTime, getSpeechTheme, getSueArr, getSueType, scheduleDifferenceId } from "@/utils/api.js";

export default {
  name: "Speech",
  components: {
    Loading: () => import('@/components/Loading.vue')
  },
  data() {
    let _this = this;
    return {
      project: [],
      search: '',
      isLoading: false,
      noneShowList: false,
      searchwordshow: '',
      keywords: [ 'Service Mesh', '架构师', '团队管理', '微服务', '数据库', '大数据', 'AI', 'SQL', '人工智能', '运维' ],
      scheduleData: { address:'', endTime:'', startTime:'' },
      scheduleDifference: scheduledifference(_this.global.year),
    };
  },
  methods: {
    toNext: function (p, i) {
      // console.log("toNext  ",p,i);
      switch (p) {
        case "subject":
          this.$router.push({
            path: `/${this.global.year}/subject`,
            name: `Subject${this.global.year}`,
            query: { id: i },
          });
          break;
        case "course":
          this.$router.push({
            path: `/${this.global.year}/course`,
            name: `Course${this.global.year}`,
            query: { id: i },
          });
          break;
        default:
          break;
      }
    },
    toSearch: function (s, e) {
      this.isLoading = true;
      let key = "";
      if (s == "btn" || s == "enter") {
        key = this.search;
      }
      if (s == "tag") {
        key = e.currentTarget.innerHTML;
      }
      // console.log(key);

      this.noneShowList = false;
      let showFlag = false;
      $("section").each(function () {
        var sec = $(this);
        sec.hide();
        sec.find(".course-div").each(function () {
          var self = $(this);
          var title = self.find(".course-title").text();
          var flag = true;
          if (key) {
            if (title.indexOf(key) == -1) {
              flag = false;
            }
            self.find(".tag").each(function () {
              if ($(this).text().indexOf(key) != -1) {
                flag = true;
              }
            });
          }
          // console.log(flag);
          if (flag) {
            sec.show();
            self.show();
          } else {
            self.hide();
          }
        });
        console.log();
        if(sec.css('display') && sec.css('display')!='none') {
          showFlag = true;
        }
      });
      this.noneShowList = !showFlag;
      this.searchwordshow = key;
      setTimeout(()=>{
        this.isLoading = false;
      }, 100)
    },
    getProject: function () {
      this.project = [];
      this.$http
        .jsonp(getSpeechTheme + sid(this.global.year))
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          if (data.errno == 0) {
            console.log("data: ",data.data);
            this.project = data.data || [];
            // 特殊排课
            if(this.scheduleDifference && typeof this.scheduleDifference==='object') {
              this.getDifference();
            }
          }
        });
    },
    getSimple() {
      this.$http
        .jsonp(getMeetingTime + sid(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(res);
          if (res.status === "200" && res.data) {
            this.scheduleData.address = res.data.address;
            this.scheduleData.startTime = res.data.startTime;
            this.scheduleData.endTime = res.data.endTime;
          }
        })
    },
    setDifferenceKey(date, groupid, times) {
      let theDate = this.global.formatTime(date, 'YMD')
      let startDate = this.global.formatTime(this.scheduleData.startTime, 'YMD');
      let endDate = this.global.formatTime(this.scheduleData.endTime, 'YMD');
      let day = theDate>=startDate && theDate<endDate ? 1 : 2;
      let index = times.slice(0,2).includes(':') || times.slice(0,2)<'12' ? 0 : 1;
      let period = index===0||index===1 ? ['am', 'pm'][index] : ''
      let key = `${this.global.year}_${day}_${groupid}_${period}`
      return key;
    },
    getDifference() {
      let url = getSueType + scheduleDifferenceId;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          console.log("difference ", res);
          if (res.errno == 0 && res.data) {
            let type = '';
            let typeList = [];
            for(let item of res.data) {
              type = item.typeName && typeof item.typeName==='string' && item.typeName.toLocaleLowerCase();
              if(type && type.includes('giac')) {
                typeList.push({id:item.id, name:type.split('#')[1]});
              }
            }
            this.getScheduleDifference(typeList);
          }
        })
    },
    getScheduleDifference(typeList) {
      for(let index in Object.keys(this.scheduleDifference)) {
        this.scheduleDifference[Object.keys(this.scheduleDifference)[index]].courses = [];
        this.$forceUpdate();
      }
      let url = getSueArr + scheduleDifferenceId;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          console.log("difference ", res, typeList);
          if (res.errno == 0 && res.data) {
            let subject={}, differenceKey='';
            for(let index in this.project) {
              subject = this.project[index];
              differenceKey = this.setDifferenceKey(subject.date, subject.groupId, subject.times);
              for(let typeItem of typeList) {
                if(this.scheduleDifference[typeItem.name] && typeItem.name==differenceKey) {
                  this.scheduleDifference[typeItem.name].courses = [];
                  this.scheduleDifference[typeItem.name].timeStamp = new Date().getTime();
                  let course = {}, lecturers=[], lecturer='', times=[], titles=[], title='';
                  for(let index in res.data[0]) {
                    let course = res.data[0][index];
                    // console.log(course);
                    // schedule-difference
                    lecturers = course.content && course.content.length>0 && course.content.split(' /// ');
                    if(course.content_note && course.content_note.length>0) {
                      times = (course.content_note.split('scheduleTitle--')[0] || '').replace('time--','').replace(/\r\n/g,'').split('-');
                      // titles = (course.content_note.split('scheduleTitle--')[1] || '').replace(/^\r\n/,'').split('\r\n');
                    }
                    this.scheduleDifference[typeItem.name].courses.push({
                      title: course.title,
                      courseTitle: course.title,
                      courseid: '',
                      date: subject.date,
                      startTime: times[0],
                      endTime: times[1],
                      name: lecturers[0].split(' || ')[0] && lecturers[0].split(' || ')[0]!=='客户技术高层' ? lecturers[0].split(' || ')[0] : '',
                      company: lecturers[0].split(' || ')[0] && lecturers[0].split(' || ')[0]!=='客户技术高层' && lecturers[0].split(' || ')[1] ? lecturers[0].split(' || ')[1] : '',
                      position: lecturers[0].split(' || ')[0] && lecturers[0].split(' || ')[0]!=='客户技术高层' && lecturers[0].split(' || ')[2] ? lecturers[0].split(' || ')[2] : '',
                      thumbs: '',
                      id: '', // 讲师id ？
                      sid: sid(this.global.year),
                      snid: subject.id,
                      averageScore: null,
                      calculationTime: null,
                      hash: null,
                      sort: '0',
                      tag: 'default',
                    });
                    /* 
                       thumbs: "https://f.msup.com.cn/lmw87Z8JG2axIp6NJxedNyNi4Sm6.JPG?imageMogr2/crop/!2812x2812a531a293"
                    */
                  }
                  this.project[index].schedulingCourse = [ ...this.scheduleDifference[typeItem.name].courses ];
                }
              }
            }
          }
        })
    },
  },
  created() {
    this.getSimple();
    this.getProject();
  },
};
</script>

<style lang="scss" scoped>
// * {
//   font-size: 14px;
// }
a,
a:hover,
a:visited {
  color: #337ab7;
  text-decoration: none;
}
// div
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
b,
i,
em,
div {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}
</style>

<style lang="scss">
#speech {
  // margin-top: 125px;
  font-size: 14px;
  text-align: left;
  .banner {
    // margin-top: 125px;
    width: 100%;
    height: 300px;
    background: #ffffff;
    text-align: center;
    h1 {
      padding-top: 55px;
      padding-bottom: 35px;
      margin: 0;
      letter-spacing: 8px;
      line-height: 50px;
      font-family: Source Han Sans CN;
      text-align: center;
      font-size: 40px;
      font-weight: 500;
      color: #2c3e50;
    }
    .input {
      max-width: 610px;
      margin: 0 auto;
      margin-bottom: 25px;
      display: flex;
      input {
        width: 78%;
        // border: 1px solid #0b70a4;
        border: 1px solid var(--deep-color);
        border-right: none;
        height: 48px;
        line-height: 48px;
        color: #cccccc;
        font-size: 16px;
        background: #ffffff;
        padding-left: 20px;
        outline: none;
      }
      button {
        height: 50px;
        line-height: 48px;
        width: 22%;
        background: var(--deep-color);
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        outline: none;
        margin-left: 0px;
        margin-top: -1px;
        padding: 0px;
      }
      button:hover {
        opacity: 0.8;
      }
    }
    .keyword {
      max-width: 767px;
      margin: 0 auto;
      a {
        font-size: 16px;
        color: #999999;
        padding-left: 10px;
        padding-right: 10px;
        line-height: 30px;
        cursor: pointer;
      }
    }
  }
  .project {
    width: 100%;
    background: #fafbfc;
    padding-top: 50px;
    padding-bottom: 50px;
    .projectz {
      max-width: 1200px;
      margin: 0 auto;
      section {
        margin-bottom: 30px;
        * {
          box-sizing: border-box;
          margin: 0;
        }
        .all_a {
          display: block;
          width: 100%;
          background-color: #fff;
          box-shadow: 0 1px 2px #ccc;
          margin-bottom: 25px;
          padding: 20px 25px;
          cursor: pointer;
          h1 {
            font-size: 19px;
            color: var(--deep-color);
          }
          p {
            font-size: 12.5px;
            color: #808080;
            line-height: 18.75px;
            padding-top: 15px;
          }
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            flex: none;
            width: 32%;
            background-color: #fff;
            border: 1px solid #e1e1e1;
            margin-bottom: 20px;
            margin-left: 2%;
            .all {
              padding: 18px 25px;
              padding-bottom: 25px;
              display: block;
              .top {
                display: flex;
                .le {
                  // width: 40%;
                  width: 115px;
                  height: 125px;
                  padding-top: 7px;
                  margin-right: 10px;
                  .img {
                    width: 100%;
                    height: 95%;
                    border-radius: 7px;
                    float: left;
                    overflow: hidden;
                    background-image: url('../assets/img/header-logo.png');
                    background-image: url('../assets/img/logo2022.png');
                    background-repeat: no-repeat;
                    background-size: 96% auto;
                    background-position: center;
                    &.blank {
                      border: 1px solid #eeeeee;
                    }
                    img {
                      width: 100%;
                      height: 100%;
                      // object-fit: cover;
                      object-fit: contain;
                      object-position: top;
                    }
                  }
                }
                .ri {
                  padding-left: 9px;
                  width: 75.3%;
                  float: left;
                  h1 {
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: var(--deep-color);
                    line-height: 22px;
                    padding-top: 7px;
                    padding-bottom: 8px;
                    border-bottom: 1px solid #d6dce3;
                    cursor: pointer;
                  }
                  h2 {
                    font-size: 13px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #3e3a39;
                    line-height: 20px;
                    padding-top: 10px;
                    span {
                      font-size: 18px;
                      font-family: PingFang SC;
                      font-weight: bold;
                      color: #484848;
                      line-height: 31px;
                    }
                  }
                }
              }
              .bot {
                display: none;
                // margin-top: 14px;
                // padding-bottom: 15px;
              }
            }
          }
          li:nth-child(3n + 1) {
            margin-left: 0px;
          }
        }
      }
      .search_blank {
        width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 1px;
        color: #666666;
      }
    }
  }
  .isblank {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 90px;
    padding-bottom: 40px;
    border-bottom: 1px solid transparent;
  }
}

// mob
@media (max-width: 1024px) {
  #speech {
    .banner {
      h1 {
        font-size: 30px;
      }
    }
    .project {
      .projectz {
        width: 90%;
        section {
          .all_a {
            padding: 15px 25px;
          }
          ul {
            li {
              width: 49%;
              &:nth-child(3n+1) {
                margin-left: 2%;
              }
              &:nth-child(2n+1) {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
    .isblank {
      padding-top: 60px;
    }
  }
}
@media (max-width: 730px) {
  #speech {
    .banner {
      .input {
        width: 90%;
      }
      .keyword {
        width: 90%;
      }
    }
    // .isblank {
    //   font-size: 16px;
    // }
  }
}
@media (max-width: 600px) {
  #speech {
    .banner {
      .input input {
        height: 45px;
        line-height: 45px;
      }
      .input button {
        height: 47px;
        line-height: 45px;
        // font-weight: 500;
        border-width: 1px;
      }
      .keyword a {
        line-height: 26px;
      }
      .input * {
        font-size: 13px !important;
      }
      .keyword * {
        font-size: 14px !important;
      }
    }
    .project {
      .projectz {
        section {
          // .all_a {
          // }
          ul {
            li {
              width: 100%;
              margin-left: 0;
              &:nth-child(3n+1) {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>