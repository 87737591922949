<template>
  <div id="speech">
    <div class="banner">
      <h1>演讲专题</h1>
      <div class="input">
        <input type="text" id="search_input" placeholder="输入领域或者关键词，查看相关主题" v-model="search" />
        <button type="button" id="search_button" @click="toSearch('btn')">搜索一下</button>
      </div>
      <div class="keyword">
        <a href="javascript:;" class="tag" @click="toSearch('tag',$event)">Service Mesh</a>
        <a href="javascript:;" class="tag" @click="toSearch('tag',$event)">架构师</a>
        <a href="javascript:;" class="tag" @click="toSearch('tag',$event)">团队管理</a>
        <a href="javascript:;" class="tag" @click="toSearch('tag',$event)">微服务</a>
        <a href="javascript:;" class="tag" @click="toSearch('tag',$event)">数据库</a>
        <a href="javascript:;" class="tag" @click="toSearch('tag',$event)">大数据</a>
        <a href="javascript:;" class="tag" @click="toSearch('tag',$event)">AI</a>
        <a href="javascript:;" class="tag" @click="toSearch('tag',$event)">SQL</a>
        <a href="javascript:;" class="tag" @click="toSearch('tag',$event)">人工智能</a>
        <a href="javascript:;" class="tag" @click="toSearch('tag',$event)">运维</a>
      </div>
    </div>
    <div class="project">
      <div class="projectz">
        <section v-for="(item,index) in project" :key="'section_'+index">
          <a class="pro_top_01 all_a" @click="toNext('subject',item.id)">
            <h1>{{item.venueName}}</h1>
            <!-- <p></p> -->
          </a>
          <ul class="pro_bot">
            <!--  -->
            <li v-for="(it,i) in item.schedulingCourse" :data-id="it.courseid" class="course-div" :key="'li_course_'+i">
              <div class="all" @click="toNext('course',it.courseid)">
                <div class="top row">
                  <div class="le">
                    <div class="img" :style="'background: url(' + it.thumbs +') no-repeat top center;'" ></div>
                  </div>
                  <div class="ri">
                    <a>
                      <h1 class="course-title">{{it.courseTitle}}</h1>
                    </a>
                    <h2>
                      <span>{{it.name}}</span><br />{{it.company}} <br />{{it.position}}
                    </h2>
                  </div>
                </div>
                <div class="bot"></div>
              </div>
            </li>
            <!--  -->
          </ul>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: "Speech",
  data(){
    return {
      project:[],
      search:''
    }
  },
  methods:{
    toNext: function(p,i){
      // console.log("toNext  ",p,i);
      switch(p){
        case 'subject':
          this.$router.push({path:"subject",name:"OneSubject",query:{id:i}});
          break;
        case 'course':
          this.$router.push({path:"course",name:"OneCourse",query:{id:i}});
          break;
        default:
          break;
      }
    },
    toSearch: function(s,e){
      let key = '';
      if(s=='btn'){
        key = this.search;
      }
      if(s=='tag'){
        key = e.currentTarget.innerHTML;
      }
      // console.log(key);

      $('section').each(function(){
        var sec = $(this);
        sec.hide();
        sec.find('.course-div').each(function(){
          var self = $(this);
          var title = self.find('.course-title').text();
          var flag = true;
          if (key){
            if (title.indexOf(key) == -1){
              flag = false;
            }  
            self.find('.tag').each(function(){
              if ($(this).text().indexOf(key) != -1) {
                flag = true;
              }
            })
          } 
          // console.log(flag);
          if (flag){
            sec.show();
            self.show();
          } else {
            self.hide();
          }
        });
      });
    },
    getInit: function(){
      var project = [];
      var url = "https://api.msup.com.cn/group/get-group-project/2682";
      this.$http
        .jsonp(url)
        .then(data => {
          return data.json();
        })
        .then(data => {
          if(data.errno == 0){
            // console.log("data: ",data.data);
            project = data.data;
            this.project = project;
          }
        })
    },
  },
  created(){
    this.getInit();
  },
};
</script>

<style lang="scss" scoped>
*{
  font-size: 14px;
}
a,
a:hover,
a:visited {
  color: #337ab7;;
  text-decoration: none;
}
// div
h1,h2,h3,h4,h5,h6,p,a,span,b,i,em,div {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}

#speech {
  // margin-top: 125px;
  text-align: left;
  .banner {
    // margin-top: 125px;
    width: 100%;
    height: 300px;
    background: #ffffff;
    text-align: center;
    h1 {
      padding-top: 55px;
      padding-bottom: 35px;
      margin: 0;
      letter-spacing: 8px;
      line-height: 50px;
      font-family: Source Han Sans CN;
      text-align: center;
      font-size: 40px;
      font-weight: 500;
      color: #2c3e50;
    }
    .input {
      max-width: 610px;
      margin: 0 auto;
      margin-bottom: 25px;
      display: flex;
      input {
        width: 78%;
        // border: 1px solid #0b70a4;
        border: 1px solid #003177;
        border-right: none;
        height: 48px;
        line-height: 48px;
        color: #cccccc;
        font-size: 16px;
        background: #ffffff;
        padding-left: 20px;
        outline: none;
      }
      button {
        height: 50px;
        line-height: 48px;
        width: 22%;
        background: #003177;
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        outline: none;
        margin-left: 0px;
        margin-top: -1px;
        padding: 0px;
      }
      button:hover {
        opacity: 0.8;
      }
    }
    .keyword {
      max-width: 767px;
      margin: 0 auto;
      a {
        font-size: 16px;
        color: #999999;
        padding-left: 10px;
        padding-right: 10px;
        line-height: 30px;
        cursor: pointer;
      }
    }
  }
  .project {
    width: 100%;
    background: #fafbfc;
    padding-top: 50px;
    padding-bottom: 50px;
    .projectz {
      max-width: 1200px;
      margin: 0 auto;
      section{
        margin-bottom: 30px;
        *{
          box-sizing: border-box;
          margin: 0;
        }
        .all_a{
          display: block;
          width: 100%;
          background-color: #fff;
          box-shadow: 0 1px 2px #ccc;
          margin-bottom: 25px;
          padding: 20px 25px;
          cursor: pointer;
          h1{
            font-size: 19px;
            color: #003177;
          }
          p{
            font-size: 12.5px;
            color: #808080;
            line-height: 18.75px;
            padding-top: 15px;
          }
        }
        ul{
          display: flex;
          flex-wrap: wrap;
          li{
            flex: none;
            width: 32%;
            background-color: #fff;
            border: 1px solid #e1e1e1;
            margin-bottom: 20px;
            margin-left: 2%;
            .all{
              padding: 18px 25px;
              padding-bottom: 25px;
              display: block;
              .top{
                display: flex;
                .le{
                  // width: 40%;
                  width: 115px;
                  height: 125px;
                  padding-top: 7px;
                  margin-right: 10px;
                  .img{
                    width: 100%;
                    height: 95%;
                    border-radius: 7px;
                    float: left;
                    background-size: cover!important;
                  }
                }
                .ri{
                  padding-left: 9px;
                  width: 75.3%;
                  float: left;
                  h1{
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #003177;
                    line-height: 22px;
                    padding-top: 7px;
                    padding-bottom: 8px;
                    border-bottom: 1px solid #D6DCE3;
                    cursor: pointer;
                  }
                  h2{
                    font-size: 13px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #3E3A39;
                    line-height: 20px;
                    padding-top: 10px;
                    span{
                      font-size: 18px;
                      font-family: PingFang SC;
                      font-weight: bold;
                      color: #484848;
                      line-height: 31px;
                    }
                  }
                }
              }
              .bot{
                display: none;
                // margin-top: 14px;
                // padding-bottom: 15px;
              }
            }
          }
          li:nth-child(3n+1) {
            margin-left: 0px;
          }
        }
      }
    }
  }
}

// mob
@media (max-width: 1024px) {
  #speech{
    .banner{
      h1{
        font-size: 30px;
      }
    }
    .project{
      .projectz{
        width: 90%;
        section{
          .all_a{
            padding: 15px 25px;
          }
          ul{
            li{
              width: 49%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 730px) {
  #speech{
    .banner{
      .input{
        width: 90%;
      }
      .keyword{
        width: 90%;
      }
    }
  }
}
@media (max-width: 600px) {
  #speech{
    .banner{
      .input input{
        height: 45px;
        line-height: 45px;
      }
      .input button{
        height: 47px;
        line-height: 45px;
        // font-weight: 500;
        border-width: 1px;
      }
      .keyword a{
        line-height: 26px;
      }
      .input *{
        font-size: 13px!important;
      }
      .keyword *{
        font-size: 14px!important;
      }
    }
    .project{
      .projectz{
        section{
          .all_a{
          }
          ul{
            li{
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>